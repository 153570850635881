<!-- 雷达库 -->
<template>
  <div
    class="wrap"
    v-infinite-scroll="load"
    infinite-scroll-distance="0"
    v-loading="loading"
  >
    <div class="get-btn cont">
      <el-dropdown
        v-if="typeof status === 'number'"
        split-button
        @command="myselect"
        type="text"
        :style="{ backgroundColor: colorStatus[status].color }"
      >
        {{ colorStatus[status].type }}
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item
            class="dropdown-item"
            :command="index"
            v-for="(item, index) in colorStatus.slice(0)"
            :key="index"
            >{{ item.type }}</el-dropdown-item
          >
        </el-dropdown-menu>
      </el-dropdown>
      <br /><br />
    </div>

    <div class="info_wrap" v-for="(item, index) in newlist" :key="index">
      <img :src="item.avatar" v-if="item.avatar" alt />
      <!-- <i :class="wayList[item.is_download].icon" :style="{color:wayList[item.is_download].color}"></i> -->

      <div v-else>
        <img
          src="./../../assets/images/test/boy.png"
          alt
          v-if="item.gender && item.gender === '男'"
        />
        <img src="./../../assets/images/test/girl.png" alt v-else />
      </div>
      <!-- <img v-lazy="item.head" alt :key="item.head" /> -->

      <div class="headSvg">
        <svg-icon
          iconClass="headMan"
          v-if="item.gender && item.gender === '男'"
        ></svg-icon>
        <svg-icon iconClass="headWoman" v-else></svg-icon>
      </div>

      <div style="width: 80%">
        <div class="bsaicInfo">
          <p>昵称：{{ item.nick_name }}</p>
          <p v-if="item.truename">姓名：{{ item.truename }}</p>
          <p v-if="item.age">
            年龄：{{ item.age }}
            <span style="margin-left: 20px"
              >学历：{{ jd_edu_name[item.edu_index] }}
            </span>
          </p>

          <p>手机：{{ item.phone }}</p>
          <p>地区：{{ item.home }}</p>
        </div>

        <div class="expect">
          <el-popover
            placement="right"
            width="800"
            trigger="hover"
            :offset="30"
          >
            <el-table :data="item.company_data" height="300">
              <el-table-column
                width="180"
                property="company_name"
                label="浏览公司"
              ></el-table-column>
              <el-table-column width="140" label="浏览公司所在地区">
                <template slot-scope="scope">{{
                  scope.row.company_place_data.city_name +
                  scope.row.company_place_data.district_name
                }}</template>
              </el-table-column>

              <el-table-column width="140" label="浏览公司职位标题">
                <template slot-scope="scope">{{ scope.row.jd_title }}</template>
              </el-table-column>

              <el-table-column width="140" label="浏览公司职位">
                <template slot-scope="scope">{{
                  scope.row.jd_position_name
                }}</template>
              </el-table-column>

              <el-table-column width="140" label="浏览时间">
                <template slot-scope="scope">{{
                  scope.row.browse_time
                }}</template>
              </el-table-column>
            </el-table>

            <!-- <el-tooltip class="item" effect="dark" placement="right"> -->
            <!-- :content="item.expect_place_str" -->

            <span slot="reference">
              <p>
                <svg-icon iconClass="site"></svg-icon>
                <span class="fontColor fontPadding">浏览公司所在地区：</span>

                <span>{{ item.company_place_one }}</span>
              </p>
              <p>
                <svg-icon iconClass="post"></svg-icon>
                <span class="fontColor fontPadding">浏览公司：</span>
                {{ item.company_name_one }}
              </p>
            </span>

            <!-- </el-tooltip> -->
          </el-popover>
          <p v-if="item.state_index > 0">
            <svg-icon iconClass="exit"></svg-icon>
            <span class="fontColor fontPadding">求职状态：</span>
            <span> {{ item.state_str }}</span>
          </p>

          <p v-if="item.position_name">
            <svg-icon iconClass="error"></svg-icon>
            <span class="fontColor fontPadding">浏览职位：</span>
            <span>{{ item.position_name }}</span>
          </p>

          <p>
            <svg-icon iconClass="time"></svg-icon>
            <span class="fontColor fontPadding">更新时间：</span>
            <span>{{ item.create_time }}</span>
          </p>
        </div>
        <div style="clear: both; margin-left: 10px" v-if="item.remarks != ''">
          <p>沟通备注：{{ item.remarks }}</p>
        </div>
        <div class="radar-btn get-btn">
          <div
            class="comment radar-comment"
            @click="
              centerDialogVisible = true;
              info = item;
              remarks = info.remarks;
            "
          >
            沟通备注
          </div>

          <el-dropdown
            split-button
            type="text"
            @command="commandHandle"
            :style="{ backgroundColor: statusList[item.status].color }"
          >
            {{ statusList[item.status].type }}
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                :command="{ index: index1, item: item }"
                v-for="(item1, index1) in statusList.slice(1)"
                :key="index1"
                >{{ item1.type }}</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </div>

        <div class="chat_div">
          <i>
            <img
              class="chat"
              @click="onFuzhi(item)"
              title="复制微信"
              src="./../../assets/images/icon_img/weixin.png"
            />
          </i>

          <i>
            <img
              class="chat"
              @click="onChatDetail(item)"
              title="聊一聊"
              src="./../../assets/images/icon_img/chat.png"
            />
          </i>
          <i
            style="float: left"
            :class="wayList[item.is_download].icon"
            :style="{ color: wayList[item.is_download].color }"
          ></i>
        </div>
      </div>
    </div>

    <el-dialog
      title="沟通备注"
      :visible.sync="centerDialogVisible"
      width="500px"
      center
    >
      <textarea class="area" v-model="remarks"></textarea>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="ResumeRemark">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import { getRadar, changeRadarRemarks, changeRadarStatus } from "@/api/tool";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      loading: true,
      list: [],

      //查询条件
      status: 0,
      colorStatus: [
        { color: "#ccc", type: "未标记" },
        { color: "#F56C6C", type: "不合适" },
        { color: "#E6A23C", type: "未接通" },
        { color: "#67C23A", type: "可面试" },
      ],

      statusList: [
        { color: "#ccc", type: "未标记" },
        { color: "#F56C6C", type: "不合适" },
        { color: "#E6A23C", type: "未接通" },
        { color: "#67C23A", type: "可面试" },
      ],
      wayList: [
        { icon: "iconfont iconfenxiang", color: "#67c23a" },
        {
          icon: "iconfont iconxiazai",
          color: "#67c23a",
        },
      ],
      //沟通备注
      centerDialogVisible: false,
      remarks: "",
      info: {},
      page: 1,
      jd_edu_name: [
        "学历不限",
        "初中",
        "中专",
        "高中",
        "大专",
        "本科",
        "硕士",
        "博士",
        "MBA",
      ],
    };
  },
  //监听属性 类似于data概念
  computed: {
    newlist() {
      return this.list.filter((item) => {
        if (item.province != "" && item.city != "") {
          item.home = item.province + item.city;
        } else if (item.district_name != "" && item.district_name != null) {
          item.home = item.district_name;
        } else {
          item.home = "未获取";
        }
        item.company_name_one = "";
        item.company_place_one = "";

        item.company_data.filter((v, i) => {
          if (i < 1) {
            item.company_name_one = v.company_name;
            item.company_place_one =
              v.company_place_data.city_name +
              v.company_place_data.district_name;
          } else {
            return;
          }
        });

        return item;
      });
    },
  },
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    // 复制手机号到剪切板
    onFuzhi(item) {
      //获取数据跳
      //console.log(item.phone);

      const save = function (e) {
        e.clipboardData.setData("text/plain", item.phone);
        e.preventDefault(); // 阻止默认行为
      };
      document.addEventListener("copy", save); // 添加一个copy事件
      document.execCommand("copy"); // 执行copy方法
      this.$message({ message: "复制微信号成功", type: "success" });
    },

    // 进入聊天详情页面
    onChatDetail(item) {
      //获取数据跳转到聊天页面
      console.log(item);
      this.$router.push({
        path: "/chatdetail",
        query: {
          elite_id: item.elite_id,
          jd_id: 0,
        },
      });
    },

    //沟通备注
    ResumeRemark() {
      console.log(this.info);
      this.loading = true;
      changeRadarRemarks({
        elite_id: this.info.elite_id,
        remarks: this.remarks,
      }).then((res) => {
        if (res.code === 0) {
          console.log(res);
          getRadar().then((res) => {
            if (res.code === 0) {
              this.list = res.data.radar_list;
              this.loading = false;
            }
          });
          this.centerDialogVisible = false;
        }
      });
      console.log(this.remarks);
    },
    commandHandle(value) {
      this.loading = true;
      changeRadarStatus({
        elite_id: value.item.elite_id,
        status: value.index + 1,
      }).then((res) => {
        if (res.code === 0) {
          getRadar().then((res) => {
            if (res.code === 0) {
              this.list = res.data.radar_list;
              this.loading = false;
            }
          });
        }
      });
      console.log(value);
    },
    load() {
      this.page++;
      console.log("load:page=", this.page);
      console.log(this.page);
      getRadar({ page: this.page, status: this.status }).then((res) => {
        if (res.code === 0) {
          if (res.data.radar_list.length > 0) {
            this.list.push(...res.data.radar_list);
          } else {
            this.$message.success("已经没有了！");
          }

          this.loading = false;
        }
      });
    },

    //下拉筛选菜单
    myselect(command) {
      console.log(command);

      this.status = command;

      // getResumeStatus(data).then((res) => {
      //   if (res.code === 0) {
      //     this.getData()
      //   }
      // })
      this.page = 0;
      this.list = [];
      this.load();
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    console.log("create:page=", this.page);
    getRadar({ page: this.page }).then((res) => {
      if (res.code === 0) {
        console.log(res.data.radar_list);
        this.list = res.data.radar_list;
        this.loading = false;
      }
      this.loading = false;
    });
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style scoped lang="scss">
/* //@import url(); 引入公共css类 */
.wrap {
  // margin-top: 80px;
  min-height: 80vh;
  @include padding;
  min-width: 1100px;
  padding-bottom: 0;
}
.info_wrap {
  width: 1000px;
  // height: 80px;
  margin-bottom: 10px;
  background-color: #fff;
  padding: 20px 20px;
  color: $jd_fontColor;
  font-size: $jd_fontSize;
  /* text-align: center; */
  /* line-height: 200px; */
  display: flex;
  position: relative;
  cursor: pointer;
  margin: auto;
}
.info_wrap:hover {
  background-color: rgba(255, 255, 255, 0.5);
}

.fontPadding {
  padding-left: 10px;
}
p {
  margin: 0;
  line-height: 27px;
}

img {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  margin: auto 0px;
}
.headSvg {
  position: relative;
  left: -10px;
  top: 0;
  z-index: 50;
  margin: 10px 0px auto 0px;
}
/*基本信息 */
.bsaicInfo {
  margin-left: 10px;
  float: left;
  width: 230px;

  /* background-color: red; */
}
// .selfAppraisal {
//   /* 多行超出隐藏 */
//   display: -webkit-box;
//   -webkit-box-orient: vertical;
//   -webkit-line-clamp: 3;
//   overflow: hidden;
//   height: 57px;
//   height: 48px\0;
// }
/* 期望 */
.expect {
  float: left;

  width: 400px;
}
.expect p {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.radar-btn {
  position: absolute;
  top: 5px;
  right: 5px;
}
.radar-btn + i {
  position: absolute;
  bottom: 20px;
  right: 10px;
}
.radar-comment {
  margin-left: 7px;
  float: left;
}
.head {
  font-size: 50px;
  color: brown;
}
.is-down {
  width: 50px;
  height: 24px;
  vertical-align: middle;
}
.area {
  border: 1px solid #ff9900;
  width: 450px;
  height: 100px;
  min-width: 450px;
  max-width: 450px;
  font-size: 16px;
  color: $selfColor;
  // text-align: center;
  // margin: 0 auto;
  display: inline-block;
  font-family: auto;
}
.area:focus {
  outline: none !important;
  border: 1px solid #719ece;
  box-shadow: 0 0 10px #719ece;
}

.chat {
  width: 30px;
  height: 30px;
  float: left;
}

.chat_div {
  float: right;
  margin-right: -60px;
  margin-top: 90px;
  line-height: 30px;
}
.iconfont {
  font-size: 28px;
}
.cont {
  text-align: center;
}
</style>
